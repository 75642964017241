.contacts {
    position: relative;

    &__map {
        position: relative;
        height: 531px;
        background-color: var(--color-line);
        overflow: hidden;
        border: 1px solid var(--color-line);
        box-shadow: 0 2px 5px rgba($color: #000, $alpha: .1);        

        @include respond-to(media-md) {
            height: 360px;
        }
    }

    &__content {
        padding-right: 50px;

        @include respond-to(media-md) {
            padding-right: 0;
        }
    }

    &__info {
        margin: -10px;

        &__item {
            padding: 10px;
            display: flex;
            flex-direction: column;
        }

        &__card {
            background-color: #fff;
            
            overflow: hidden;
            height: 100%;
            padding: 30px;
            box-shadow: 0 2px 5px rgba($color: #000, $alpha: .1);

            &__title {
                font-weight: 600;
                margin-bottom: 15px;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                i {
                    flex: 0 0 auto;
                    width: 20px;
                    height: 20px;
                    max-width: 100%;
                    margin-right: 10px;
                    margin-top: -3px;
                    color: var(--color-primary);

                    svg, img {
                        display: block;
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                span {
                    display: block;
                    flex: 0 0 auto;
                    max-width: 100%;
                }
            }
            &__des {
                font-size: 14px;
            }
            &__socio {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    margin-top: 15px;

                    &:first-child {
                        margin-top: 0;
                    }
                }

                a {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    i {
                        flex: 0 0 auto;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 18px;
                        height: 18px;
                        margin-right: 10px;

                        svg, img {
                            display: block;
                            max-height: 100%;
                            max-height: 100%;
                        }
                    }
                }
            }
        }
    }
}