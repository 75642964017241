.partners {
    position: relative;
    margin: -10px;
    display: flex;
    align-items: center;

    &__item {
        padding: 10px;
    }
    &__card {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 110px;
        border: 1px solid var(--color-line);
        
        padding: 20px;
        transition: all 0.3s ease;
        box-shadow: 0 2px 5px rgba($color: #000, $alpha: .1);

        &:hover {
            border-color: var(--color-primary);
        }

        img, svg {
            display: block;
            max-height: 100%;
            max-width: 100%;
        }
    }
}