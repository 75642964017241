.reviews {
    position: relative;

    &__item {
        position: relative;
    }
    &__card {
        font-style: italic;
        padding: 60px 60px 60px 60px;
        background-color: #fff;
        border: 1px solid var(--color-line);
        position: relative;

        @include respond-to(media-xs) {
            padding: 30px;
        }
        
        &::after {
            content: '"';
            position: absolute;
            right: 20px;
            bottom: 0;
            color: rgba($color: #000, $alpha: .3);
            font-size: 120px;
            line-height: 1;
            height: 60px;
            z-index: 1;

            @include respond-to(media-xs) {
                font-size: 90px;
                right: 10px;
                bottom: -20px;
            }
        }
        &::before {
            content: '"';
            position: absolute;
            left: 10px;
            top: 0;
            color: rgba($color: #000, $alpha: .3);
            font-size: 120px;
            line-height: 1;
            height: 60px;
            z-index: 1;
            
            @include respond-to(media-xs) {
                font-size: 90px;
                left: 0;
            }
        }

        & > div {
            position: relative;
            z-index: 2;
        }
    }

    &__nav {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 10px;

        &__bullet {
            display: block;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: var(--color-line);
            margin: 5px;
            cursor: pointer;

            &.reviews__nav__bullet__active {
                background-color: var(--color-primary);
                cursor: default;
            }
        }
    }
}