.services {
    position: relative;
    margin: -15px;

    &__item {
        padding: 15px;
        display: flex;
        flex-direction: column;
    }
    &__card {
        background-color: #fff;        
        box-shadow: 0 2px 5px rgba($color: #000, $alpha: .1);
        height: 100%;
        position: relative;
        overflow: hidden;
        padding-top: 240px;
        display: flex;
        flex-direction: column;
        z-index: 1;

        @include respond-to(media-md) {
            padding-top: 180px;
        }

        &:hover .services__card__img {
            transform: scale(1.1,1.1);
        }

        &__img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: cover;
            z-index: 1;
            transition: all 0.3s ease;
            
            &::after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
                background-color: rgba($color: #000, $alpha: .3);
            }
        }

        &__body {
            height: 100%;
            background-color: rgba($color: #fff, $alpha: .7);
            position: relative;
            padding: 30px;
            z-index: 2;
        }

        &__title {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 10px;

            @include respond-to(media-md) {
                font-size: 18px;
            }
        }
        &__des {
            font-size: 14px;
        }
    }
}