.news {
    position: relative;
    margin: -15px;

    @include respond-to(media-md) {
        margin: -10px;
    }

    &__item {
        padding: 15px;
        display: flex;
        flex-direction: column;

        @include respond-to(media-md) {
            padding: 10px;
        }
    }
    &__card {
        background-color: var(--color-blockbg);
        padding: 20px;
        
        overflow: hidden;
        height: 100%;
        position: relative;
        box-shadow: 0 2px 5px rgba($color: #000, $alpha: .1);

        &:hover .news__card__img div {
            transform: scale(1.2,1.2);
        }

        &__img {
            height: 260px;
            background-color: var(--color-line);
            margin-bottom: 15px;
            overflow: hidden;
            position: relative;

            div {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-position: 50% 50%;
                background-repeat: no-repeat;
                background-size: cover;
                transition: all 0.3s ease;
                z-index: 1;
            }
        }
        &__title {
            font-weight: 600;
            margin-bottom: 10px;
        }
        &__des {
            font-size: 14px;
        }
        &__date {
            position: absolute;
            right: 25px;
            top: 25px;
            background-color: rgba($color: #fff, $alpha: .8);
            font-size: 14px;
            line-height: 1;
            padding: 5px 10px;
            border-radius: 5px;
            z-index: 2;
        }
    }
}