@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html {
	scroll-behavior: smooth;
	background-color: var(--color-bg);
}

body {
	background-color: var(--color-bg);
	color: var(--color-text);
	font-family: var(--font-main);
	font-size: 16px;
	line-height: 1.4;

	@include respond-to(media-md) {
		font-size: 14px;
	}

	&.no-scroll {
		overflow-y: hidden;
	}
}
html, body {	
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

main {
	overflow: hidden;
	flex: 1 0 0%;
	padding-top: 105px;

	@include respond-to(media-md) {
		padding-top: 72px;
	}
}

img {
	display: block;
}

a {
	color: var(--color-text);
	text-decoration: underline;
	cursor: pointer;
	transition: all 0.3s ease;

	&:hover,
	&:focus {
		color: var(--fifth-color);
		text-decoration: none;
	}
}

h1 {
	font-size: 42px;
	margin: 40px 0 20px 0;
	padding: 0;
	font-weight: 600;

	&:first-child {
		margin-top: 0;
	}

	@include respond-to(media-lg) {
		font-size: 32px;
	}
	@include respond-to(media-md) {
		font-size: 28px;
		margin-bottom: 15px;
	}
}

h2 {
	font-size: 36px;
	margin: 40px 0 20px 0;
	padding: 0;
	font-weight: 600;
	text-transform: uppercase;

	&:first-child {
		margin-top: 0;
	}

	@include respond-to(media-lg) {
		font-size: 28px;
	}
	@include respond-to(media-md) {
		font-size: 24px;
		margin-bottom: 15px;
	}
}

h3 {
	font-size: 30px;
	margin: 30px 0 15px 0;
	padding: 0;
	font-weight: 600;
	text-transform: uppercase;

	&:first-child {
		margin-top: 0;
	}
	@include respond-to(media-md) {
		font-size: 22px;
		margin-bottom: 15px;
	}
}

h4 {
	font-size: 26px;
	margin: 30px 0 15px 0;
	padding: 0;
	font-weight: 600;

	&:first-child {
		margin-top: 0;
	}
	@include respond-to(media-md) {
		font-size: 20px;
		margin-bottom: 15px;
	}
}

h5 {
	font-size: 24px;
	margin: 30px 0 15px 0;
	padding: 0;
	font-weight: 600;

	&:first-child {
		margin-top: 0;
	}
	@include respond-to(media-md) {
		font-size: 18px;
		margin-bottom: 10px;
	}
}

h6 {
	font-size: 20px;
	margin: 30px 0 15px 0;
	padding: 0;
	font-weight: 600;

	&:first-child {
		margin-top: 0;
	}
	@include respond-to(media-md) {
		font-size: 16px;
		margin-bottom: 10px;
	}
}

.block__text {
	position: relative;

	p {
		margin: 15px 0 0 0;

		&:first-child {
			margin-top: 0;
		}
	}

	ul {
		list-style: none;
		margin: 15px 0 0 0;
		padding: 0;

		&:first-child {
			margin-top: 0;
		}

		li {
			margin-top: 5px;
			padding-left: 20px;
			position: relative;

			&:first-child {
				margin-top: 0;
			}
			&::after {
				content: "";
				position: absolute;
				left: 0;
				top: 9px;
				width: 6px;
				height: 6px;
				border-radius: 50%;
				background-color: var(--color-primary);
			}
		}
	}
}

.block__maxwidth {
	max-width: 800px;
	margin-bottom: 40px;

	@include respond-to(media-md) {
		max-width: 100%;
		margin-bottom: 20px;
	}
}

.block__padd {
	padding: 60px 0;

	@include respond-to(media-md) {
		padding: 30px 0;
	}

	&:last-child {
		padding-bottom: 100px;

		@include respond-to(media-md) {
			padding-bottom: 60px;
		}
	}
	&:first-child {
		padding-top: 100px;

		@include respond-to(media-md) {
			padding-top: 60px;
		}
	}

	&.block__padd__last {
		padding-bottom: 100px;

		@include respond-to(media-md) {
			padding-bottom: 60px;
		}
	}
}

.block__padd2 {
	padding: 100px 0;

	@include respond-to(media-md) {
		padding: 60px 0;
	}

	& + .block__padd {
		padding-top: 100px;

		@include respond-to(media-md) {
			padding-top: 60px;
		}
	}
}

.block__padd__right {
	padding-right: 60px;

	@include respond-to(media-md) {
		padding-right: 0;
	}
}
.block__padd__left {
	padding-left: 60px;

	@include respond-to(media-md) {
		padding-left: 0;
	}
}

.link__video {
	position: relative;
	display: block;
	height: 430px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	overflow: hidden;
	

	@include respond-to(media-md) {
		height: 260px;
	}

	&::before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($color: #000, $alpha: .3);
		z-index: 1;
	}

	&::after {
		content: "";
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		width: 60px;
		height: 60px;
		border-radius: 50%;
		background-color: rgba($color: #fff, $alpha: .4);
		background-image: url('../img/ic-play.svg');
		background-repeat: no-repeat;
		background-size: 20px 20px;
		background-position: 50% 50%;
		z-index: 2;
		transition: all 0.3s ease;
	}

	&:hover, &:focus {
		&::after {
			transform: translate(-50%,-50%) scale(1.2,1.2);
		}
	}
}

.block__img {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	min-height: 430px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	overflow: hidden;

	@include respond-to(media-md) {
		min-height: 260px;
	}

	span {
		justify-self: end;
		font-style: italic;
		background-color: rgba($color: #fff, $alpha: .8);
		text-align: center;
		padding: 20px;
		
	}
}

.block__color {
	background-color: var(--color-blockbg);
}

.text__color {
	color: var(--color-primary);
}

.info {
	text-align: center;
	color: var(--color-primary);
	text-align: center;
	font-style: italic;
	font-size: 18px;
	margin: -15px;

	@include respond-to(media-md) {
		font-size: 16px;
	}

	&__item {
		display: flex;
		flex-direction: column;
		padding: 15px;
	}
	&__card {
		background-color: var(--color-bgheader);
		padding: 30px;
		height: 100%;
	}
}