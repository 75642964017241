.workalgorithm {
    position: relative;
    margin: -10px -10px -20px -10px;
    display: flex;
    flex-wrap: wrap;

    &__item {
        padding: 20px 10px;
        display: flex;
        flex-direction: column;
        flex: 0 0 auto;
        width: 20%;
        max-width: 100%;

        @include respond-to(media-md) {
            width: 33.3333%;
        }
        @include respond-to(media-sm) {
            width: 50%;
        }
        @include respond-to(media-xs) {
            width: 100%;
        }
    }
    &__card {
        height: 100%;
        position: relative;
        border: 1px solid var(--color-line);
        background-color: #fff;
        
        padding: 40px 20px 20px 20px;
        transition: all 0.3s ease;
        box-shadow: 0 2px 5px rgba($color: #000, $alpha: .1);
        
        @include respond-to(media-md) {
            min-height: 180px;
        }
        @include respond-to(media-xs) {
            min-height: auto;
        }

        &:hover {
            border-color: var(--color-primary);
        }

        &__number {
            position: absolute;
            left: 20px;
            top: -20px;
            width: 40px;
            height: 40px;
            color: #fff;
            background-color: var(--color-primary);
            text-align: center;
            line-height: 40px;
            border-radius: 5px;
            font-weight: 600;
            font-size: 24px;
            z-index: 2;
        }
        &__des {
            position: relative;

            ul {
                list-style: none;
                margin: -5px;
                padding: 10px 0 0 0;
                display: flex;

                li {
                    padding: 5px;
                }
                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 30px;
                    height: 30px;
                    border-radius: 5px;
                    background-color: var(--color-primary);
                    color: #fff;

                    &:hover, &:focus {
                        transform: translateY(-5px);
                        box-shadow: 0 2px 2px rgba($color: #000, $alpha: .2);
                    }

                    svg {
                        display: block;
                        max-width: 16px;
                        max-height: 16px;;
                    }
                }
            }
        }
    }
}