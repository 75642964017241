.progress {
    position: relative;

    &__item {
        margin-top: 15px;

        &:first-child {
            margin-top: 0;
        }
    }
    &__card {
        font-size: 14px;
        font-weight: 600;

        &__bar {
            height: 10px;
            background-color: rgba($color: #000, $alpha: .1);
            position: relative;

            i {
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                z-index: 2;
                background-color: var(--color-primary);
            }
        }
    }
}