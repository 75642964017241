.advantages {
    position: relative;
    
    padding: 60px;
    overflow: hidden;

    @include respond-to(media-md) {
        padding: 40px 30px;
    }

    &__body {
        margin: -15px;
        position: relative;
        z-index: 3;

        @include respond-to(media-md) {
            margin: -10px;
        }
    }
    &__item {
        padding: 15px;
        display: flex;
        flex-direction: column;

        @include respond-to(media-md) {
            padding: 10px;
        }
    }
    &__img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 2;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($color: #000, $alpha: .5);
            z-index: 1;
        }
    }
    &__card {
        background-color: rgba($color: #fff, $alpha: .9);
        padding: 30px;
        text-align: center;
        overflow: hidden;
        
        height: 100%;

        @include respond-to(media-md) {
            padding: 30px 20px;
        }

        &__title {
            font-size: 60px;
            font-weight: 700;
            line-height: 1;
            margin-bottom: 10px;

            @include respond-to(media-md) {
                font-size: 40px;
            }
        }
        &__des {
            font-size: 14px;
        }
    }
}