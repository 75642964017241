
// $media-mxl: 1440px;
// $media-xl: 1439px;
$media-mlg: 1200px;
$media-lg: 1199px;
$media-mmd: 992px;
$media-md: 991px;
$media-msm: 768px;
$media-sm: 767px;
$media-mxs: 575px;
$media-xs: 575px;

@mixin mediaBg() {
	@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	    @content;
    }
}

@mixin respond-to($media) {
	// @if $media == media-mxl {
	// 	@media (min-width: $media-mxl) {
	// 		@content;
	// 	}
	// }
	// @else if $media == media-xl {
	// 	@media (max-width: $media-xl) {
	// 		@content;
	// 	}
	// }
	@if $media == media-mlg {
		@media (min-width: $media-mlg) {
			@content;
		}
	}
	@else if $media == media-lg {
		@media (max-width: $media-lg) {
			@content;
		}
	}
	@else if $media == media-mmd {
		@media (min-width: $media-mmd) {
			@content;
		}
	}
	@else if $media == media-md {
		@media (max-width: $media-md) {
			@content;
		}
	}
	@else if $media == media-msm {
		@media (min-width: $media-msm) {
			@content;
		}
	}
	@else if $media == media-sm {
		@media (max-width: $media-sm) {
			@content;
		}
	}
	@else if $media == media-mxs {
		@media (min-width: $media-mxs) {
			@content;
		}
	}
	@else if $media == media-xs {
		@media (max-width: $media-xs) {
			@content;
		}
	}
}