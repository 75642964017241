:root {
	--font-main: 'Roboto', Arial;

	--color-bg: #fff;
	--color-text: #000;
    // --color-primary: #74acff;
    // --color-primary: #a67c52;
    --color-primary: #CFB282;

	--color-line: #ced4da;
    --color-bgheader: #f6f6f6;
    --color-blockbg: #e9ecef;
}