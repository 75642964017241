.header {
	padding: 20px 0;
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	background-color: var(--color-bgheader);
	height: 105px;
	border-bottom: 1px solid var(--color-line);
	z-index: 200;

	@include respond-to(media-md) {
		padding: 10px 0;
		height: 72px;
	}

	&__logo {
		position: relative;

		a {
			display: block;
			text-transform: uppercase;
			text-decoration: none;
			font-size: 20px;
			height: 64px;
			width: 320px;

			@include respond-to(media-md) {
				width: 260px;
				height: 52px;
			}

			svg, img {
				display: block;
				max-height: 100%;
				max-width: 100%;
			}
		}
	}
	&__menu {
		list-style: none;
		margin: 0 -20px;
		padding: 0;
		text-transform: uppercase;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		font-weight: 500;

		li {
			padding: 0 20px;
		}
		a {
			display: block;
			text-decoration: none;
			position: relative;
			padding: 4px 0;

			&:hover, &:focus {
				color: var(--color-primary);

				&::before {
					width: 100%;
				}
			}

			&::before {
				content: "";
				position: absolute;
				left: 0;
				bottom: 0;
				height: 2px;
				width: 0;
				background-color: var(--color-primary);
				transition: all 0.3s ease;
				z-index: 1;
			}

			&.active {
				color: var(--color-primary);
				
				&::before {
					width: 100%;
				}
			}
		}
	}

	&__btnmenu {
		display: block;
		width: 30px;
		height: 20px;
		position: relative;
		cursor: pointer;

		span {
			position: absolute;
			left: 0;
			right: 0;
			height: 2px;
			background-color: var(--color-text);
			border-radius: 2px;
			transition: all 0.3s ease;

			&.line1 {
				top: 0;
			}
			&.line2 {
				top: 9px;
			}
			&.line3 {
				top: 18px;
			}
		}

		&.header__btnmenu__active span {
			&.line2 {
				opacity: 0;
			}
			&.line1 {
				top: 9px;
				transform: rotate(45deg);
			}
			&.line3 {
				top: 9px;
				transform: rotate(-45deg);
			}
		}
	}

	&__socio {
		list-style: none;
		margin: 0 -15px 15px -15px;
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		li {
			padding: 0 15px;
		}
		a {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			font-size: 14px;

			&:hover, &:focus {
				color: var(--color-primary);
				text-decoration: none;
			}

			i {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 16px;
				height: 16px;
				margin-right: 10px;
				color: var(--color-primary);

				svg, img {
					display: block;
					max-width: 100%;
					max-height: 100%;
				}
			}
		}
	}
}