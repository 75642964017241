.mobile-nav {
	position: fixed;
	top: 71px;
	left: 100%;
	width: 100%;
	bottom: 0;
	z-index: 199;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-top: 60px;
	padding-bottom: 60px;
	background: var(--color-bgheader);
	transition: all 0.3s ease-in;

	&.mobile-nav__open {
		left: 0;
	}

	&__body {
		height: 100%;
		width: 100%;
		max-width: 100%;
		flex: 1 0 0%;
	}

	&__footer {
		flex: 0 0 auto;
    	width: auto;
		max-width: 100%;
	}
	&__menu {
		list-style: none;
		margin: 0 0 60px 0;
		padding: 0;
		font-size: 16px;
		font-weight: 600;
		text-transform: uppercase;
		text-align: center;

		li {
			margin-top: 15px;

			&:first-child {
				margin-top: 0;
			}
		}

		a {
			display: inline-block;
		}
	}

	&__contacts {
		position: relative;
		list-style: none;
		margin: 0;
		padding: 0;
		text-align: center;

		&__item {
			margin-top: 30px;

			&:first-child {
				margin-top: 0;
			}
		}
		&__title {
			font-size: 16px;
			font-weight: 600;
			margin-bottom: 5px;
		}
		&__des {
			position: relative;
		}
		&__link {
			display: flex;
			align-items: center;
			justify-content: center;

			i {
				display: flex;
				align-items: center;
				justify-content: center;
				flex: 0 0 auto;
				max-width: 100%;
				width: 18px;
				height: 18px;
				margin-right: 10px;
				color: var(--color-primary);

				img, svg {
					display: block;
					max-width: 100%;
					max-height: 100%;
				}
			}
			span {
				flex: 0 0 auto;
				width: auto;
    			max-width: 100%;
			}
		}
	}

	&__socio {
		list-style: none;
		padding: 0;
		font-weight: 500;
		margin: -10px;
		display: flex;
		align-items: center;
		justify-content: center;

		li {
			padding: 10px;
		}

		a {
			display: flex;
			align-items: center;
			justify-content: center;

			i {
				flex: 0 0 auto;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 28px;
				height: 28px;
				margin-right: 10px;

				svg, img {
					display: block;
					max-height: 100%;
					max-height: 100%;
				}
			}
		}
	}
}