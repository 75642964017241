.footer {
	position: relative;
	font-size: 14px;
	background-color: var(--color-bgheader);

	@include respond-to(media-sm) {
		text-align: center;
	}

	&__linea {
		padding: 30px 0;
	}
	&__lineb {
		border-top: 1px solid var(--color-line);
		padding: 20px 0;
	}

	&__logo {
		position: relative;

		a {
			display: block;
			height: 52px;
			width: 260px;

			@include respond-to(media-md) {
				margin: 0 auto;
			}

			svg, img {
				display: block;
				max-height: 100%;
				max-width: 100%;
			}
		}
	}

	&__contacts {
		list-style: none;
		margin: 0;
		padding: 0;

		&__item {
			margin-top: 15px;

			&:first-child {
				margin-top: 0;
			}
		}
		&__title {
			margin-bottom: 5px;
			font-weight: 500;
		}

		&__link {
			display: flex;
			align-items: center;

			@include respond-to(media-sm) {
				justify-content: center;
			}

			i {
				flex: 0 0 auto;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 18px;
				height: 18px;
				margin-right: 10px;
				color: var(--color-primary);

				svg, img {
					display: block;
					max-height: 100%;
					max-height: 100%;
				}
			}
		}
	}

	&__socio {
		list-style: none;
		margin: 0px;
		padding: 0;
		font-weight: 500;

		@include respond-to(media-sm) {
			margin: -10px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		li {
			padding: 10px 0 0 0;

			@include respond-to(media-sm) {
				padding: 10px;
			}

			&:first-child {
				@include respond-to(media-mmd) {
					padding-top: 0;
				}
			}
		}

		a {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			
			@include respond-to(media-sm) {
				justify-content: center;
			}

			i {
				flex: 0 0 auto;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 28px;
				height: 28px;
				margin-right: 10px;

				svg, img {
					display: block;
					max-height: 100%;
					max-height: 100%;
				}
			}
		}
	}
}