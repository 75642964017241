.welcome {
    position: relative;
    background-color: var(--color-line);

    &__slider {
        position: relative;

        &__content {
            padding: 160px 0 120px 0;
            color: var(--color-bg);
            max-width: 50%;
            min-height: 690px;

            @include respond-to(media-lg) {
                max-width: 60%;
            }
            @include respond-to(media-md) {
                max-width: 70%;
                padding: 60px 0;
                min-height: 410px;
            }
            @include respond-to(media-sm) {
                max-width: 80%;
            }
            @include respond-to(media-xs) {
                max-width: 100%;
            }
        }
        &__item {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            .container {
                height: 100%;
                position: relative;
                z-index: 2;
            }
        }
        &__img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: cover;
            z-index: 1;

            // &::after {
            //     content: "";
            //     position: absolute;
            //     left: 0;
            //     top: 0;
            //     width: 100%;
            //     height: 100%;
            //     background-color: rgba($color: #000, $alpha: .7);
            //     z-index: 1;
            // }
        }
        &__title {
            font-size: 42px;
            line-height: 1.2;
            font-weight: 600;
            margin: 0 0 20px 0;
            text-transform: uppercase;
            padding: 0;

            @include respond-to(media-lg) {
                font-size: 32px;
            }
            @include respond-to(media-md) {
                font-size: 28px;
                margin-bottom: 15px;
            }
        }
        &__des {
            font-size: 20px;

            @include respond-to(media-md) {
                font-size: 16px;
            }
        }

        &__nav {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            padding-bottom: 20px;
            z-index: 4;
    
            display: flex;
            align-items: center;
            justify-content: center;

            @include respond-to(media-md) {
                padding-bottom: 0px;
            }
    
            &__bullet {
                display: block;
                margin: 10px;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: var(--color-line);
                cursor: pointer;
                    
                @include respond-to(media-md) {
                    margin: 5px;
                    width: 9px;
                    height: 9px;
                }
    
                &.welcome__slider__nav__bullet__active {
                    background-color: var(--color-primary);
                    cursor: default;
                }
            }
        }
    }
}