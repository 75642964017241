.typescargo {
    position: relative;
    margin: -15px;

    @include respond-to(media-md) {
        margin: -10px;
    }

    &__item {
        position: relative;
        padding: 15px;

        @include respond-to(media-md) {
            padding: 10px;
        }
    }
    &__card {
        position: relative;
        height: 260px;
        text-align: center;
        overflow: hidden;
        text-align: center;
        font-weight: 500;
        

        @include respond-to(media-md) {
			height: 200px;
		}

        &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            height: 2px;
            background-color: var(--color-primary);
            z-index: 3;
            width: 0;
            transition: all 0.3s ease;
        }

        &:hover {

            &::after {
                width: 100%;
            }

            .typescargo__card__img {
                transform: scale(1.1,1.1);
            }
        }

        &__title {
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            padding: 20px 15px;
            background-color: rgba($color: #fff, $alpha: .8);
            z-index: 2;
        }
        &__img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: cover;
            z-index: 1;
            transition: all 0.3s ease;
        }
    }
}