/* Base */
// @import './base/reset';

@import './base/vars';
@import './base/mixins';
// @import './base/fontsAutoGen';
@import './base/base';

/* Blocks */
@import "blocks/_advantages.scss";
@import "blocks/_contacts.scss";
@import "blocks/_favorites.scss";
@import "blocks/_footer.scss";
@import "blocks/_header.scss";
@import "blocks/_mobile-nav.scss";
@import "blocks/_news.scss";
@import "blocks/_partners.scss";
@import "blocks/_progress.scss";
@import "blocks/_reviews.scss";
@import "blocks/_services.scss";
@import "blocks/_typescargo.scss";
@import "blocks/_welcome.scss";
@import "blocks/_workalgorithm.scss";

/* No styles code below. Only in modules */
/* Не пишите CSS код ниже. Только в подключаемых файлах */
